import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@material-ui/core";

const PhoneInput = ({
  countries,
  country,
  onChange,
  disabled,
  disableUnderline = true,
  className = "",
}) => {
  /* Iran, Turkey and Russia hidden in dropdown */

  const [filteredCountries, setFilteredCountries] = useState(countries);

  useEffect(() => {
    setFilteredCountries((countries) =>
      countries.filter(
        (item) =>
          item.countryName !== "Iran" &&
          item.countryName !== "Russia" &&
          item.countryName !== "Turkey"
      )
    );
  }, []);

  return (
    <Select
      className={className}
      disabled={disabled}
      disableUnderline={disableUnderline}
      value={country}
      onChange={onChange}
      label="L"
      renderValue={(value) => {
        return (
          <div style={{ background: "transparent" }}>
            {value.phone ? `(+${value.phone})` : value.countryCode}
          </div>
        );
      }}
    >
      {filteredCountries.map((item) => (
        <MenuItem key={item.countryCode} value={item}>
          {item.countryName} ({item.countryCode})
        </MenuItem>
      ))}
    </Select>
  );
};

export default PhoneInput;
